import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import ButtonComponent from '../../components/ButtonComponent';
import IconErrorState from '../../components/icons/IconErrorState';
import messages from './messages';
import './styles.scss';
export const ErrorPageTemplate = ({ logoutClickHandler, isUserUnauthorized = false, customErrorTitle, customErrorMessage, }) => {
    const title = isUserUnauthorized
        ? messages.unauthorized_header
        : customErrorTitle !== null && customErrorTitle !== void 0 ? customErrorTitle : messages.default_header;
    const message = isUserUnauthorized
        ? messages.unauthorized_tip
        : customErrorMessage !== null && customErrorMessage !== void 0 ? customErrorMessage : messages.default_tip;
    return (_jsxs("div", { className: "sdr-error-page", children: [_jsx("div", { className: "sdr-error-page__banner", children: _jsx("span", { className: "sdr-error-page__img", children: _jsx(IconErrorState, {}) }) }), _jsx("h1", { children: _jsx(FormattedMessage, { ...title }) }), _jsx("div", { className: "sdr-error-page__tip", children: _jsx(FormattedMessage, { ...message }) }), _jsx(ButtonComponent, { className: "oc-btn", onButtonClick: logoutClickHandler, children: _jsx(FormattedMessage, { ...messages.reload }) })] }));
};
export default ErrorPageTemplate;
