import SplunkOtelWeb from '@splunk/otel-web';
import { POS_API_URL, SALES_API_URL } from '../../constants';
import { EventDetailsSpanProcessor } from './event-details-span-processor';
import { PerformanceDevSpanProcessor } from './performance-dev-span-processor';
import { UserInformationSpanProcessor } from './user-information-span-processor';
export const applyCustomAttributesOnSpan = (span, request) => {
    var _a, _b;
    const header = (request === null || request === void 0 ? void 0 : request.headers) ? new Headers(request.headers) : new Headers();
    const attributes = {
        'tm.correlation_id': (_a = header.get('tmps-correlation-id')) !== null && _a !== void 0 ? _a : 'unknown',
    };
    try {
        const body = JSON.parse(((_b = request === null || request === void 0 ? void 0 : request.body) === null || _b === void 0 ? void 0 : _b.toString()) || '{}');
        if (body.query) {
            const [, queryType, graphqlOperation] = body.query.match(/(mutation|query) (\w+)/);
            if (graphqlOperation) {
                const spanName = `GraphQL ${queryType} ${graphqlOperation}`;
                attributes['tm.gql_operation'] = graphqlOperation;
                span.updateName(spanName);
            }
        }
    }
    catch (_) {
    }
    finally {
        span.setAttributes(attributes);
    }
};
const createProcessorsFacade = (userInformationSpanProcessor, eventDetailsSpanProcessor) => ({
    setUserInformation: (userInformation) => userInformationSpanProcessor.setUserInformation(userInformation),
    setEventDetails: (eventDetails) => eventDetailsSpanProcessor.setEventDetails(eventDetails),
});
export const splunkRumAgentStarter = (rumAccessToken) => {
    const userInformationSpanProcessor = new UserInformationSpanProcessor();
    const eventDetailsSpanProcessor = new EventDetailsSpanProcessor();
    SplunkOtelWeb.init({
        realm: 'us1',
        rumAccessToken,
        applicationName: 'sales.ui',
        deploymentEnvironment: process.env.ISM_ENV,
        ignoreUrls: [/api-js\.mixpanel\.com/, /www\.google-analytics\.com/],
        tracer: {
            spanProcessors: [userInformationSpanProcessor, eventDetailsSpanProcessor, new PerformanceDevSpanProcessor()],
        },
        instrumentations: {
            fetch: {
                propagateTraceHeaderCorsUrls: [new RegExp(`^${POS_API_URL}.*`), new RegExp(`^${SALES_API_URL}.*`)],
                applyCustomAttributesOnSpan,
            },
            xhr: {
                clearTimingResources: true,
            },
        },
    });
    return createProcessorsFacade(userInformationSpanProcessor, eventDetailsSpanProcessor);
};
