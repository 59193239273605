// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, InfoIcon } from '@ticketmaster/aurora';
import IconSwipeCard from '@ticketmaster/tm1pos-web-shared/components/icons/IconSwipeCard';
import SmallSpinnerComponent from '@ticketmaster/tm1pos-web-shared/components/SmallSpinnerComponent';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { clearCartLocation } from '../../../../../../middleware/mixpanel-constants';
import { clearCart, promptOrderDelete } from '../../../../../App/actions';
import messages from '../../../../messages';
import { selectEMVSetupError } from '../../../../selectors';
import { selectCardPaymentEnabled } from '../../checkout-selectors';
import { selectFormToggles } from '../../selectors';
import { isSwipeable } from './utils';

export class SubmitButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cancelInProgress: false,
    };
  }

  get getDisabled() {
    const { invalid, checkoutInProgress } = this.props;
    return invalid || checkoutInProgress;
  }

  get checkoutBtn() {
    const { cancelInProgress } = this.state;
    const disabled = this.getDisabled || cancelInProgress;
    return (
      <>
        <Button
          type="button"
          onClick={this.cancelCurrentCart}
          variant="transparent"
          className="sdr-checkout__btn--cancel"
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button id="checkout-btn" type="submit" className="sdr-checkout__btn--checkout" disabled={disabled}>
          {this.props.checkoutInProgress ? <SmallSpinnerComponent /> : <FormattedMessage {...messages.checkoutNow} />}
        </Button>
      </>
    );
  }

  get swipeBtn() {
    const disabled = this.getDisabled;
    const swipeClassNames = classNames({
      'swipe-card-btn': true,
      'swipe-card-btn--disabled': disabled,
    });
    return (
      <div id="swipe-btn" className={swipeClassNames}>
        <div className="checkout-swipe__btn">
          {disabled ? <InfoIcon type="filled" size="regular" /> : <IconSwipeCard />}
        </div>
        <div className="checkout-swipe__text">
          {disabled ? <FormattedMessage {...messages.fillInfo} /> : <FormattedMessage {...messages.swipeCardNow} />}
        </div>
        <div className="checkout-swipe__cancel">
          {' '}
          <Button type="button" onClick={this.cancelCurrentCart} variant="transparent">
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    );
  }

  cancelCurrentCart = (clickEvent) => {
    this.preventPerformingCheckout(clickEvent);

    const { isOrderUnpaid } = this.props;
    if (isOrderUnpaid) {
      this.props.dispatch(promptOrderDelete());
    } else {
      this.setState({ cancelInProgress: true });
      const clearPlace = clearCartLocation.CANCEL_BUTTON;
      this.props.dispatch(clearCart(clearPlace));
    }
  };

  preventPerformingCheckout = (clickEvent) => {
    clickEvent.preventDefault();
  };

  render() {
    const showSwipe = isSwipeable(
      this.props.paymentMethod,
      this.props.manualCardEntry,
      this.props.ifConnectPaymentDevice,
      this.props.emvSetupError,
      this.props.cardPaymentEnabled,
    );
    const btnClassNames = classNames({
      'sdr-checkout__btn-wrap': true,
      'sdr-checkout__btn-wrap--card': showSwipe,
    });
    return <div className={btnClassNames}>{showSwipe ? this.swipeBtn : this.checkoutBtn}</div>;
  }
}

SubmitButton.propTypes = {
  paymentMethod: PropTypes.string,
  checkoutInProgress: PropTypes.bool,
  invalid: PropTypes.bool,
  manualCardEntry: PropTypes.bool,
  ifConnectPaymentDevice: PropTypes.bool,
  emvSetupError: PropTypes.string,
  isOrderUnpaid: PropTypes.bool,
  cardPaymentEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const formToggles = selectFormToggles(state);
  const emvSetupError = selectEMVSetupError(state);
  const cardPaymentEnabled = selectCardPaymentEnabled(state);
  return {
    ...formToggles,
    emvSetupError,
    cardPaymentEnabled,
  };
};
const mapDispatchToProps = (dispatch) => ({ dispatch });

const connectedSubmitButton = connect(mapStateToProps, mapDispatchToProps)(SubmitButton);

export default connectedSubmitButton;
