import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import faultTolerance from '@ticketmaster/tm1pos-web-shared/utils/faultTolerance';
import { call } from 'redux-saga/effects';
import { POS_GRAPHQL_URL } from '../../constants';
import { EVENT_DETAILS_QUERY } from './pos-api-queries';
import type { GetEventDetailsResult } from './model/get-event-details-result';
import type { IdentifierInput } from './model/identifier-input';
import type { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { Effect } from 'redux-saga/effects';

export function* getEventDetails(
  eventId: IdentifierInput,
  feeEquivalenceClassId: IdentifierInput,
): Generator<Effect, GraphQLResult<GetEventDetailsResult>, GraphQLResult<GetEventDetailsResult>> {
  return yield call(fetchFromSalesApi, {
    url: POS_GRAPHQL_URL,
    query: EVENT_DETAILS_QUERY,
    options: {
      variables: { eventId, feeEquivalenceClassId },
      faultToleranceHandler: faultTolerance.retryIfOpcodeLock,
    },
  });
}
