import { defineMessages } from 'react-intl';
export default defineMessages({
    Cancel: {
        id: 'shared.App.Cancel',
        defaultMessage: 'Cancel',
    },
    Ok: {
        id: 'shared.App.Ok',
        defaultMessage: 'OK',
    },
    Apply: {
        id: 'shared.App.Apply',
        defaultMessage: 'Apply',
    },
    limitedReached: {
        id: 'shared.TextareaComponent.limitedReached',
        defaultMessage: 'Maximum character limit reached',
    },
    remaining: {
        id: 'shared.TextareaComponent.remaining',
        defaultMessage: '{remaining, plural, one {{remaining} character remaining} other {{remaining} characters remaining}}',
    },
    noSellTypesFound: {
        id: 'shared.SellTypesDropdown.noSellTypesFound',
        defaultMessage: 'No sell types found for {searchValue}',
    },
    searchForType: {
        id: 'shared.SellTypesDropdown.searchForType',
        defaultMessage: 'Search for type',
    },
    remainingInventory: {
        id: 'shared.InventoryTypesDropdown.remainingInventory',
        defaultMessage: 'Remaining inventory',
    },
    noInventory: {
        id: 'shared.InventoryTypesDropdown.noInventory',
        defaultMessage: 'No inventory',
    },
    grandTotalAmount: {
        id: 'shared.CheckoutSummary.grandTotalAmount',
        defaultMessage: 'Grand Total',
    },
    subtotalAmount: {
        id: 'shared.CheckoutSummary.subtotalAmount',
        defaultMessage: 'Subtotal',
    },
    fees: {
        id: 'shared.CheckoutSummary.fees',
        defaultMessage: 'Fees',
    },
    feesAndTaxes: {
        id: 'shared.CheckoutSummary.feesAndTaxes',
        defaultMessage: 'Fees & Taxes',
    },
    cashTendered: {
        id: 'shared.CheckoutSummary.cashTendered',
        defaultMessage: 'Cash Tendered',
    },
    changeDue: {
        id: 'shared.CheckoutSummary.changeDue',
        defaultMessage: 'Change Due',
    },
    selectOption: {
        id: 'shared.DropDown.selectOption',
        defaultMessage: 'Select an option',
    },
    userConfigurationErrorHeader: {
        id: 'shared.ErrorPage.header.user_configuration_error',
        defaultMessage: 'User Configuration Error',
    },
    userConfigurationErrorTip: {
        id: 'shared.ErrorPage.tip.user_configuration_error',
        defaultMessage: 'There is an issue with your user configuration. Please sign in with a different account or contact Ticketmaster support.',
    },
});
