import { SYSTEM_ID } from '@ticketmaster/tm1pos-web-shared/constants';
import { getInventoryEventId } from '@ticketmaster/tm1pos-web-shared/store/events/utils';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { HOST_EVENT_TYPE } from '../../constants';
import { setCartEventFees } from '../../services/pos-api-client/pos-api-event-fees';
import { getErrorTypeByErrorCode } from '../../services/sales-api-client/model/set-cart-event-fees-response';
import { getCartIdWithoutSystemInfo } from '../../utils/get-cart-id-without-system-info';
import { selectFormattedReservedItems } from '../EventDetailPage/selectors/main';
import { selectEventType } from '../EventDetailPage/selectors/selectEventIds';
import { setEventFees, setEventFeesError, setEventFeesLoading, setEventFeesSuccess } from './event-fees-actions';
import { selectCartId } from './selectors';
import type { SetCartEventFeesResponse } from '../../services/sales-api-client/model/set-cart-event-fees-response';
import type { CartItem } from '../EventDetailPage/model/graphql';
import type { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { EventStore } from '@ticketmaster/tm1pos-web-shared/store/events/events.slice';

export function* cartEventFees({ payload }: ReturnType<typeof setEventFees>) {
  const eventType: string = yield select(selectEventType);

  if (eventType !== HOST_EVENT_TYPE) {
    throw new Error('Unsupported for events other than HST');
  }

  yield put(setEventFeesLoading());

  const cartId: string = yield select(selectCartId);
  const currentEventDetails: EventStore = yield select(selectCurrentEventDetails);
  const gqlResult: GraphQLResult<SetCartEventFeesResponse> = yield call(setCartEventFees, {
    cartIdentifier: {
      id: getCartIdWithoutSystemInfo(cartId),
      system: SYSTEM_ID.HOST,
      subSystem: currentEventDetails?.hostName ?? '',
    },
    eventCode: currentEventDetails?.eventCode ?? '',
    eventId: getInventoryEventId(currentEventDetails.id),
    eventFees: payload.eventFees,
  });

  const result = getValidatedEventFeesResponse(gqlResult);

  if (result) {
    const formattedItemsForCart: CartItem[] = yield select(selectFormattedReservedItems(result.items, false));

    const formattedData = {
      ...result,
      items: formattedItemsForCart,
    };

    yield put(setEventFeesSuccess(formattedData));
  } else {
    const errorCode = gqlResult?.errors?.at(0)?.extensions?.errorCode;
    yield put(setEventFeesError({ error: getErrorTypeByErrorCode(errorCode) }));
  }
}

function getValidatedEventFeesResponse(gqlResponse: GraphQLResult<SetCartEventFeesResponse>) {
  if (gqlResponse?.data?.setCartEventFees?.items?.length) {
    return gqlResponse.data.setCartEventFees;
  }
  return null;
}

export function* watchSetEventFees() {
  yield takeLatest(setEventFees.type, cartEventFees);
}

export const eventFeesSaga = [watchSetEventFees];
