import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call } from 'redux-saga/effects';
import { POS_GRAPHQL_URL } from '../../constants';
import { SET_CART_EVENT_FEES_MUTATION } from '../sales-api-client/api-queries';
import type { CartResponse } from '../sales-api-client/model/cart-response';
import type { CartEventFeesInput } from '../sales-api-client/model/set-cart-event-fees-input';
import type { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { Effect } from 'redux-saga/effects';

export function* setCartEventFees(
  params: CartEventFeesInput,
): Generator<Effect, GraphQLResult<CartResponse>, GraphQLResult<CartResponse>> {
  return yield call(fetchFromSalesApi, {
    url: POS_GRAPHQL_URL,
    query: SET_CART_EVENT_FEES_MUTATION,
    options: {
      variables: {
        input: params,
      },
    },
  });
}
