import { createSelector } from '@reduxjs/toolkit';
import ErrorPageTemplate from '@ticketmaster/tm1pos-web-shared/containers/ErrorPageTemplate/ErrorPageTemplate';
import messages from '@ticketmaster/tm1pos-web-shared/messages';
import { selectIsUserUnauthorized } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { USER_TIMEOUT } from '../../actions-constants';
import type { AnyAction } from '@reduxjs/toolkit';
import type { RootState } from '@ticketmaster/tm1pos-web-shared/store/store';
import type { FormattedMessage } from 'react-intl';

type ErrorPageProps = {
  dispatch: (action: AnyAction) => void;
  isUserUnauthorized?: boolean;
};

export const ErrorPage = ({ dispatch, isUserUnauthorized = false }: ErrorPageProps) => {
  const errors = useSelector((state: RootState) => state.errorPage.errors);
  const [errorPageTitle, setErrorPageTitle] = useState<FormattedMessage.MessageDescriptor | undefined>(undefined);
  const [errorPageMessage, setErrorPageMessage] = useState<FormattedMessage.MessageDescriptor | undefined>(undefined);

  const handleLogoutButtonClick = () => {
    dispatch({ type: USER_TIMEOUT });
  };

  useEffect(() => {
    if (errors?.find((error) => error.code === 'MIXED_SUPPORT_SERVICE_PROFILES')) {
      setErrorPageTitle(messages.userConfigurationErrorHeader);
      setErrorPageMessage(messages.userConfigurationErrorTip);
    }
  }, [errors]);

  return (
    <ErrorPageTemplate
      logoutClickHandler={handleLogoutButtonClick}
      isUserUnauthorized={isUserUnauthorized ?? false}
      customErrorTitle={errorPageTitle}
      customErrorMessage={errorPageMessage}
    />
  );
};

const mapStateToProps = createSelector(selectIsUserUnauthorized, (isUserUnauthorized) => ({
  isUserUnauthorized,
}));
const mapDispatchToProps = (dispatch: any) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
