// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { withMatcher } from '@ticketmaster/tm1pos-web-shared/utils/withMatcher';
import { CHECKOUT_NOW, CREDIT_CARD_PREPOPULATE_MANUAL, DO_DELIVERY } from './actions-constants';

export const populateCreditCardData = (data) => ({
  type: CREDIT_CARD_PREPOPULATE_MANUAL,
  ...data,
});

export const checkout = () => ({
  type: CHECKOUT_NOW,
});

export const doDelivery = (
  deliveryMethod,
  order,
  deliveryConfig,
  hostName,
  eventDetails,
  payments,
  items,
  deliveryRequest,
) => ({
  type: DO_DELIVERY,
  deliveryMethod,
  order,
  deliveryConfig,
  hostName,
  eventDetails,
  payments,
  items,
  deliveryRequest,
});

export const checkoutMutationInProgress = withMatcher((inProgress) => ({
  type: 'CHECKOUT_MUTATION_IN_PROGRESS',
  inProgress,
}));
