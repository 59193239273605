import { createContext, useContext, useMemo, useState } from 'react';
import type { DeliveryFormFields } from '../app/typings/delivery-form-fields';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

type PurchaseContextType = {
  deliveryFormFields?: DeliveryFormFields;
  setDeliveryFormFields: Dispatch<SetStateAction<DeliveryFormFields | undefined>>;
};

type PurchaseProviderProps = {
  children: ReactNode;
};

const PurchaseContext = createContext<PurchaseContextType | undefined>(undefined);

const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const [deliveryFormFields, setDeliveryFormFields] = useState<DeliveryFormFields | undefined>();

  const componentValue = useMemo(
    () => ({ deliveryFormFields, setDeliveryFormFields }),
    [deliveryFormFields, setDeliveryFormFields],
  );

  return <PurchaseContext.Provider value={componentValue}>{children}</PurchaseContext.Provider>;
};

const usePurchaseContext = () => {
  const context = useContext(PurchaseContext);
  if (!context) {
    throw new Error('usePurchaseContext must be used within a PurchaseProvider');
  }
  return context;
};

export { PurchaseProvider, usePurchaseContext };
