import type { IdentifierInput } from '../model/identifier-input';

export const mapLegacyEventIdToIdentifierInput = (
  legacyCompositeEventId: string,
  system: string,
  subSystem: string,
): IdentifierInput => ({
  id: parseEventIdFromCompositeId(legacyCompositeEventId),
  system,
  subSystem,
});

const parseEventIdFromCompositeId = (legacyCompositeEventId: string) => {
  const eventIdMatch = legacyCompositeEventId.match(/ARX-(.*)-HST-(.*)/i);

  if (!eventIdMatch || !eventIdMatch.length) {
    throw new Error('Unsupported event ID.');
  }

  const [, archticsId, hostId] = eventIdMatch;
  return archticsId || hostId;
};
