import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    errors: [],
};
const errorPageSlice = createSlice({
    name: 'errorPage',
    initialState,
    reducers: {
        setErrorPage: (state, action) => {
            state.errors = action.payload;
        },
    },
});
export const { setErrorPage } = errorPageSlice.actions;
export const errorPageReducer = errorPageSlice.reducer;
