// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
  window.location.href = '/error';
};

const renderRoute = (cb, componentModule) => {
  cb(null, componentModule.default);
};
export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const handleCustomersPageRoute = () => async (nextState, cb) => {
    try {
      const component = await import('containers/CustomersPage/tm1pos-orders-container');
      renderRoute(cb, component);
    } catch (err) {
      errorLoading(err);
    }
  };

  return [
    {
      path: '/error',
      name: 'error',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/App/components/ErrorPage/ErrorPage');

          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/',
      name: 'home',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/HomePage');
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/event/:id',
      name: 'Event Detail',
      async getComponent(nextState, cb) {
        try {
          const [reducer, component] = await Promise.all([
            import('containers/EventDetailPage/reducer'),
            import('containers/EventDetailPage'),
          ]);

          store.injectReducer('event', reducer.default);
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/customers',
      name: 'customers',
      getComponent: handleCustomersPageRoute(),
    },
    {
      path: '/customers/:accountId/:orderId',
      name: 'orderDetails',
      getComponent: handleCustomersPageRoute(),
    },
    {
      path: '/reports',
      name: 'reports',
      async getComponent(nextState, cb) {
        try {
          const [reducer, sagas, component] = await Promise.all([
            import('containers/ReportPage/reducer'),
            import('containers/ReportPage/sagas'),
            import('containers/ReportPage'),
          ]);

          store.injectReducer('report', reducer.default);
          store.injectSagas(sagas.default);

          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/settings',
      name: 'settings',
      async getComponent(nextState, cb) {
        try {
          const [component] = await Promise.all([import('containers/SettingsPage')]);

          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/authenticate',
      name: 'authenticate',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/Authenticate');
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/silentrenew',
      name: 'silentrenew',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/Silentrenew');
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '/components',
      name: 'components',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/ComponentsPage');
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
    {
      path: '*',
      name: 'notfound',
      async getComponent(nextState, cb) {
        try {
          const component = await import('containers/NotFoundPage');
          renderRoute(cb, component);
        } catch (err) {
          errorLoading(err);
        }
      },
    },
  ];
}
