import { setInventoryType } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { fetchAllEventsSuccess, selectEvent } from '@ticketmaster/tm1pos-web-shared/store/events/events.slice';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { setEventId } from './actions';
import { EVENT_ID_SET, FETCH_EVENT_DETAILS } from './constants';
import { mapIsmrtPlacesToSeatStatus } from './containers/SearchMap/mapper';
import { ismrtSnapshotDataSuccess, setSeatStatuses, subscribeToIsmrtPlaceUpdates } from './event-actions';
import { selectCurrentEventDetails, selectInventoryTypes } from './selectors/main';
import { selectSellTypeAvailabilityCounts } from './selectors/seatStatus';
import type { SeatStatusPlace } from './model/SeatStatusPlace';
import type { SellTypeAvailabityCounts } from './selectors/event-detail-selector-models';
import type { AnyAction } from '@reduxjs/toolkit';
import type { SellClass } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { EventStore } from '@ticketmaster/tm1pos-web-shared/store/events/events.slice';

function canSellInventoryType(allowedInventoryTypes: SellClass[], inventoryType: string) {
  return allowedInventoryTypes.some((type) => type.id === inventoryType);
}

export function* checkInventoryTypesAvailabilityCounts() {
  const counts: SellTypeAvailabityCounts = yield select(selectSellTypeAvailabilityCounts);
  const availableInventoryTypes = Object.keys(counts.sellTypeAvailability);
  const allowedInventoryTypes: SellClass[] = yield select(selectInventoryTypes);
  const allowedAndAvailable = availableInventoryTypes.filter((type) =>
    canSellInventoryType(allowedInventoryTypes, type),
  );
  if (allowedAndAvailable.length === 1) {
    const inventoryType = allowedInventoryTypes.find((it) => it.id === allowedAndAvailable[0]);
    yield put(setInventoryType(inventoryType));
  }
}

export function* watchSetSeatStatusesOnEventLoad() {
  yield takeLatest(EVENT_ID_SET, setSeatStatusesOnEventLoad);
}

export function* setSeatStatusesOnEventLoad() {
  const { ismrtSnapshot } = yield all({
    eventDetails: take(FETCH_EVENT_DETAILS.SUCCESS),
    ismrtSnapshot: take(ismrtSnapshotDataSuccess.type),
  });

  const seatStatusPlaces: SeatStatusPlace[] = yield call(mapIsmrtPlacesToSeatStatus, ismrtSnapshot.payload.places);

  yield put(setSeatStatuses({ places: seatStatusPlaces }));

  yield put(subscribeToIsmrtPlaceUpdates());
}

export function* watchEventListHasLoaded() {
  yield takeLatest(fetchAllEventsSuccess.type, selectEventOnDeeplink);
}

export function* selectEventOnDeeplink(action: AnyAction) {
  const eventDetails: EventStore | undefined = yield select(selectCurrentEventDetails);
  const isDeepLink = /\/event\/[^/]+$/.test(window.location.pathname);
  if (isDeepLink && !eventDetails) {
    const eventList: EventStore[] = action.payload;
    const event = eventList.filter((item) => item.id === window.location.pathname.split('event/').pop());

    if (event[0]) {
      yield put(selectEvent(event[0]));
      yield put(setEventId(event[0].id));
    }
  }
}

export const eventDetailSagas = [watchSetSeatStatusesOnEventLoad, watchEventListHasLoaded];
