// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createSelector } from '@reduxjs/toolkit';
import { renderInputComponent } from '@ticketmaster/tm1pos-web-shared/components/InputComponent';
import { renderTextAreaComponent } from '@ticketmaster/tm1pos-web-shared/components/TextareaComponent';
import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { renderCountriesSelectionComponent } from '@ticketmaster/tm1pos-web-shared/containers/countriesDropdown';
import { getCountryData } from '@ticketmaster/tm1pos-web-shared/countries';
import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { selectLanguage } from '@ticketmaster/tm1pos-web-shared/translations';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import { validateEmail } from '@ticketmaster/tm1pos-web-shared/utils/emailValidation';
import {
  normalizePhone,
  US_COUNTRY_ID,
  validatePhoneNumber,
} from '@ticketmaster/tm1pos-web-shared/utils/phoneValidation';
import classNames from 'classnames';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/max';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Field } from 'redux-form/lib/immutable';
import { ARCHTICS_EVENT_TYPE, ARCHTICS_NOTE_LIMITATION, HOST_NOTE_LIMITATION } from '../../../../../../constants';
import messages from '../../../../messages';

import './styles.scss';
import { CARD, CHECKOUT_FORM_TITLE } from '../../constants';

export const DeliveryControlFields = {
  COUNTRY_PREFIX: 'phonePre',
  DELIVERY_METHOD: 'deliveryMethod',
};

export class DeliveryForm extends Component {
  mounted = false;

  constructor(props) {
    super(props);
    this.countriesDOM = createRef();
    this.state = {
      countries: [],
      defaultCountry: undefined,
      phonePre: this.props.phonePre,
    };
  }

  async componentDidMount() {
    this.mounted = true;

    const countries = await getCountryData(this.props.locale);
    this.updateCountries(countries);

    this.state.phonePre = this.setDefaultPhonePre();

    this.setFormDefaultPhonePre();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setDefaultPhonePre() {
    const countryCode = this.props.currentEvent?.countryCode
      ? this.props.currentEvent?.countryCode
      : this.state.phonePre?.id;

    this.state.defaultCountry =
      this.state.countries.find((country) => country.key === countryCode) ||
      this.state.countries.find((country) => country.key === US_COUNTRY_ID);

    return {
      id: this.state.defaultCountry?.key,
      value: this.state.defaultCountry?.key,
      label: `+${this.state.defaultCountry?.code}`,
      name: `+${this.state.defaultCountry?.code}`,
    };
  }

  setFormDefaultPhonePre() {
    if (this.props.dispatch) {
      this.props.dispatch(change(CHECKOUT_FORM_TITLE, 'phonePre', this.state.phonePre));
    }
  }

  getPhoneNumberErrorMessage(country) {
    return country.id === US_COUNTRY_ID
      ? this.props.intl.formatMessage(messages.phoneNumberValidationError)
      : this.props.intl.formatMessage(messages.phoneNumberValidationErrorNonUS);
  }

  updateCountries = (countries) => {
    if (this.mounted) {
      this.setState({ countries });
    }
  };

  validatePhoneNumber = (phoneNumber, formState) => {
    const country = formState.get(DeliveryControlFields.COUNTRY_PREFIX) || this.state.phonePre;
    const isSmsDelivery = formState.get(DeliveryControlFields.DELIVERY_METHOD) === SMS;

    return this.isPhoneNumberValid(phoneNumber, country, isSmsDelivery)
      ? null
      : this.getPhoneNumberErrorMessage(country);
  };

  isPhoneNumberValid = (phoneNumber, country, isSmsDelivery) => {
    const isOptionalPhoneNumberBlank = !isSmsDelivery && !phoneNumber;

    return isOptionalPhoneNumberBlank || validatePhoneNumber(phoneNumber, country.id);
  };

  normalizePhoneLocal = (value) => {
    const countryCode = this.props.phonePre.id;
    return normalizePhone(value, countryCode);
  };

  formatPhoneLocal = (value) => formatIncompletePhoneNumber(value, this.props.phonePre.id);

  noteValidation = (value) => {
    const limitation =
      this.props.currentEvent.type === ARCHTICS_EVENT_TYPE ? ARCHTICS_NOTE_LIMITATION : HOST_NOTE_LIMITATION;
    return (value && value.length > limitation) || undefined;
  };

  validateEmailLocal = (value, formState) => {
    const deliveryMethod = formState.get(DeliveryControlFields.DELIVERY_METHOD);
    const linkGlobalAccount = formState.get('linkGlobalAccount');
    const isEmailValid = !validateEmail(value, deliveryMethod === EMAIL || linkGlobalAccount);
    return isEmailValid ? undefined : this.props.intl.formatMessage(messages.emailNotValid);
  };

  valueIsNotEmpty = (value) => (value ? undefined : this.props.intl.formatMessage(messages.required));

  render() {
    const {
      fullNamePlaceholder,
      emailPlaceholder,
      notePlaceholder,
      respectRotEntryToken,
      currentEvent,
      phoneNumberPlaceholder,
      deliveryMethod,
      isManualEntry,
      isEmvSupported,
      shouldRequireFullName,
      paymentMethod,
    } = this.props;

    const limitedNumber = currentEvent.type === ARCHTICS_EVENT_TYPE ? ARCHTICS_NOTE_LIMITATION : HOST_NOTE_LIMITATION;
    const wrapperPadding = 64;
    const phoneNumberClassName = classNames({
      'sdr-checkout__input': true,
      'sdr-checkout--counties': true,
      'sdr-checkout__phoneNumbWrap': true,
    });
    const classNamePN = classNames('sdr-checkout__input', 'sdr-checkout__input--phoneNumber');
    const isFullNameRequired =
      (respectRotEntryToken && currentEvent.rotatingEntryTokenEnabled) ||
      (paymentMethod === CARD && isEmvSupported && (isManualEntry || shouldRequireFullName));
    const phoneNumberComponent = (
      <div
        className={phoneNumberClassName}
        ref={(c) => {
          this.countriesDOM = c;
        }}
      >
        <div className="sdr-checkout__form--fixed">
          <Field
            name={DeliveryControlFields.COUNTRY_PREFIX}
            component={renderCountriesSelectionComponent}
            type="text"
            defaultValue={this.state.defaultCountry}
            dataProvider={this.state.countries}
            height="25vh"
            width={this.countriesDOM.offsetWidth - wrapperPadding}
          />
        </div>
        <Field
          name="phoneNumber"
          className={classNamePN}
          component={renderInputComponent}
          format={this.formatPhoneLocal}
          placeholder={phoneNumberPlaceholder}
          type="text"
          normalize={this.normalizePhoneLocal}
          validate={this.validatePhoneNumber}
          handleOnBlur={this.handleOnBlur}
          handleOnFocus={this.handleOnFocus}
        />
      </div>
    );
    const fullNameComponent = (
      <Field
        name="fullName"
        className="sdr-checkout__input sdr-checkout__input--fullname"
        component={renderInputComponent}
        placeholder={fullNamePlaceholder}
        validate={isFullNameRequired ? this.valueIsNotEmpty : undefined}
      />
    );
    const emailComponent = (
      <Field
        name="email"
        className="sdr-checkout__input sdr-checkout__input--email"
        component={renderInputComponent}
        type="email"
        handleOnBlur={this.handleOnBlur}
        handleOnFocus={this.handleOnFocus}
        placeholder={emailPlaceholder}
        validate={this.validateEmailLocal}
      />
    );
    const emailAsDeliveryOrder = (
      <div className="sdr-checkout__email-delivery">
        {emailComponent}
        {fullNameComponent}
        {phoneNumberComponent}
      </div>
    );
    const printAsDeliveryOrder = (
      <div className="sdr-checkout__non-email-delivery">
        {phoneNumberComponent}
        {fullNameComponent}
        {emailComponent}
      </div>
    );
    const textAsDeliveryOrder = printAsDeliveryOrder;
    return (
      <>
        {deliveryMethod === EMAIL ? emailAsDeliveryOrder : null}
        {deliveryMethod === PRINT ? printAsDeliveryOrder : null}
        {deliveryMethod === SMS ? textAsDeliveryOrder : null}
        <Field
          name="paymentNode"
          className="sdr-checkout__paymentNode"
          noteLimited
          LimitedNumber={limitedNumber}
          component={renderTextAreaComponent}
          placeholder={notePlaceholder}
          validate={this.noteValidation}
        />
      </>
    );
  }
}

DeliveryForm.propTypes = {
  currentEvent: PropTypes.object,
  respectRotEntryToken: PropTypes.bool,
  isEmvSupported: PropTypes.bool,
  isManualEntry: PropTypes.bool,
  fullNamePlaceholder: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  notePlaceholder: PropTypes.string,
  phoneNumberPlaceholder: PropTypes.string,
  phonePre: PropTypes.object,
  intl: intlShape.isRequired,
  deliveryMethod: PropTypes.string,
  locale: PropTypes.string,
  shouldRequireFullName: PropTypes.bool,
  paymentMethod: PropTypes.string,
};

const mapStateToProps = createSelector(
  [selectLanguage, selectEmvPaymentModuleState],
  (language, emvPaymentModuleState) => ({
    locale: language,
    shouldRequireFullName: emvPaymentModuleState.rules.shouldRequireFullName,
    isEmvSupported: emvPaymentModuleState.isEmvSupported,
  }),
);

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeliveryForm));
