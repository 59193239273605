// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import mainMessages from '@ticketmaster/tm1pos-web-shared/messages';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import Big from 'big.js';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form/immutable';
import messages from '../../../../messages';
import { CHECKOUT_FORM_TITLE } from '../../constants';
import { CurrencyInput } from './components/CurrencyInput/CurrencyInput';

export class CashForm extends Component {
  constructor(props) {
    super(props);
    this.setupInitialValues(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const cashToTenderHasChanged = JSON.stringify(nextProps.cashToTender) !== JSON.stringify(this.props.cashToTender);
    if (cashToTenderHasChanged) {
      const changeDue = this.countChangeDueAmount(nextProps.cashToTender?.amount, nextProps.cashToTender?.amount);
      this.props.dispatch(
        change(CHECKOUT_FORM_TITLE, this.props.fieldNames.cashTendered, nextProps.cashToTender?.amount),
      );
      this.props.dispatch(change(CHECKOUT_FORM_TITLE, this.props.fieldNames.changeDue, changeDue));
    }
  }

  onCashTenderedChange = (value) => {
    const changeDue = this.countChangeDueAmount(value, this.props.cashToTender?.amount);
    this.props.dispatch(change(CHECKOUT_FORM_TITLE, this.props.fieldNames.cashTendered, value));
    this.props.dispatch(change(CHECKOUT_FORM_TITLE, this.props.fieldNames.changeDue, changeDue));
  };

  getCashTenderedValue = () => this.props.getFieldValue(this.props.fieldNames.cashTendered);

  getChangeDueValue = () => this.props.getFieldValue(this.props.fieldNames.changeDue);

  getCashFormLabels = () => {
    const cashTenderedLabel = this.props.intl.formatMessage(mainMessages.cashTendered);
    const changeDueLabel = this.props.intl.formatMessage(mainMessages.changeDue);

    return {
      cashTenderedLabel,
      changeDueLabel,
    };
  };

  setupInitialValues = (initProps) => {
    const cashToTender = Number(initProps.cashToTender?.amount);
    const cashTendered = cashToTender;
    const changeDue = this.countChangeDueAmount(cashTendered, cashToTender);
    this.props.dispatch(change(CHECKOUT_FORM_TITLE, initProps.fieldNames.cashTendered, cashTendered));
    this.props.dispatch(change(CHECKOUT_FORM_TITLE, initProps.fieldNames.changeDue, changeDue));
  };

  countChangeDueAmount = (cashTendered, cashToTender) => Math.max(0, new Big(cashTendered).minus(cashToTender));

  validateCashTenderedField = (value) => {
    const cashToTender = this.props.cashToTender?.amount;
    const cashTendered = parseFloat(value);
    const isValid = cashTendered >= cashToTender;
    const message = <FormattedMessage {...messages.notEnoughCashError} />;
    return isValid ? false : message;
  };

  render() {
    const hasChange = !!this.getChangeDueValue();
    const { cashTenderedLabel, changeDueLabel } = this.getCashFormLabels();
    const cashTenderedValue = this.getCashTenderedValue();

    return (
      <div className="sdr-checkout__cash">
        <Field
          name={this.props.fieldNames.cashTendered}
          validate={this.validateCashTenderedField}
          component={CurrencyInput}
          cashTenderedLabel={cashTenderedLabel}
          cashTenderedValue={cashTenderedValue}
          onValueChange={this.onCashTenderedChange}
          locale={this.props.intl.locale}
          currency={this.props.cashToTender?.currency}
        />
        <div className={classNames('sdr-checkout__change-due', { 'sdr-checkout__change-due--positive': hasChange })}>
          <label className="oc-label">{changeDueLabel}</label>
          <div className="oc-textfield">
            {this.props.intl.formatNumber(this.getChangeDueValue(), {
              ...currencyFormatOptions,
              currency: this.props.cashToTender?.currency,
            })}
          </div>
        </div>
      </div>
    );
  }
}

CashForm.propTypes = {
  intl: intlShape.isRequired,
  fieldNames: PropTypes.shape({
    cashTendered: PropTypes.string,
    changeDue: PropTypes.string,
  }),
  cashToTender: PropTypes.object,
  getFieldValue: PropTypes.func,
  shouldInitFocusOnCashTendered: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = (state) => ({
  getFieldValue: (fieldName) => formValueSelector(CHECKOUT_FORM_TITLE)(state, fieldName),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CashForm));
