import type { GetEventDetailsResult } from '../model/get-event-details-result';

export const mapLegacyIdsToEventDetailsResult = (
  eventDetails: GetEventDetailsResult,
  legacyCompositeEventId: string,
) => ({
  ...eventDetails,
  event: {
    ...eventDetails.event,
    id: legacyCompositeEventId,
  },
});
