// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ButtonComponent from '@ticketmaster/tm1pos-web-shared/components/ButtonComponent';
import IconCart from '@ticketmaster/tm1pos-web-shared/components/icons/IconShoppingCartLarge';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../messages';

import './styles.scss';

const EmptyCart = (props) => (
  <div className="sdr-checkout__empty">
    <div className="sdr-checkout__empty__icon">
      <IconCart />
    </div>
    <div className="sdr-checkout__empty__message">
      <FormattedMessage {...messages.cartEmpty} />
    </div>
    <div className="sdr-checkout__empty__btn">
      <ButtonComponent onButtonClick={props.btnHandler} className="oc-btn">
        <FormattedMessage {...messages.backToEvents} />
      </ButtonComponent>
    </div>
  </div>
);

EmptyCart.propTypes = {
  btnHandler: PropTypes.func,
};

export default EmptyCart;
