// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { renderRadioComponent } from '@ticketmaster/tm1pos-web-shared/components/RadioComponent';
import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form/lib/immutable';
import messages from '../../../../messages';

export class DeliveryTypeControls extends PureComponent {
  render() {
    const { intl, emailAsDelivery } = this.props;
    const emailTab = emailAsDelivery ? (
      <li className="oc-radio-list__item">
        <Field
          name="deliveryMethod"
          component={renderRadioComponent}
          id="emailTickets"
          value={EMAIL}
          text={intl.formatMessage(messages.emailPlaceholder)}
          type="radio"
        />
      </li>
    ) : null;
    return (
      <>
        <div className="sdr-checkout__delivery-title-wrap">
          <div className="sdr-checkout__section-title">
            <FormattedMessage {...messages.ticketDeliveryAndReceipt} />
          </div>
        </div>
        <ul className="oc-radio-list oc-radio-list--toggle checkout-delivery-options">
          <li className="oc-radio-list__item">
            <Field
              name="deliveryMethod"
              component={renderRadioComponent}
              id="smsDelivery"
              value={SMS}
              text={intl.formatMessage(messages.smsDeliveryPlaceholder)}
              type="radio"
              checked
            />
          </li>
          {emailTab}
          <li className="oc-radio-list__item">
            <Field
              name="deliveryMethod"
              component={renderRadioComponent}
              id="printTickets"
              value={PRINT}
              text={intl.formatMessage(messages.printTicketsPlaceholder)}
              type="radio"
            />
          </li>
        </ul>
      </>
    );
  }
}

DeliveryTypeControls.propTypes = {
  intl: intlShape.isRequired,
  emailAsDelivery: PropTypes.bool,
};
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(null, mapDispatchToProps)(injectIntl(DeliveryTypeControls));
