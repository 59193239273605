// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';

const EventInfo = ({ header, name, weekday, fullDate, time }) => (
  <div className={`${header}__summary__event`}>
    <div className={`${header}__summary__event--name`}>{name} </div>
    <div className={`${header}__summary__event--time`}>
      <div className="event__weekday">{weekday} </div>
      <div className="event__date">{fullDate} </div>
      <div>{time}</div>
    </div>
  </div>
);

EventInfo.propTypes = {
  header: PropTypes.string,
  name: PropTypes.string,
  weekday: PropTypes.string,
  fullDate: PropTypes.string,
  time: PropTypes.string,
};

export default EventInfo;
