import { CartIcon } from '@ticketmaster/aurora';
import IconClose from '@ticketmaster/tm1pos-web-shared/components/icons/IconClose';
import { useAppSelector } from '../../../../store/hooks';
import { selectCartItemsLength } from '../../../App/selectors';

type CheckoutHeaderProps = {
  handleClickCart: () => void;
};

export const CheckoutHeader = ({ handleClickCart }: CheckoutHeaderProps) => {
  const cartItemsLength = useAppSelector(selectCartItemsLength);

  return (
    <div className="sdr-checkout__header">
      <div className="sdr-checkout__cart">
        <CartIcon color="#fff" size={24} />
        <div className="sdr-checkout__cart-items">{cartItemsLength}</div>
      </div>
      <a role="button" onClick={handleClickCart} tabIndex={-1} className="sdr-checkout__close">
        <IconClose />
      </a>
    </div>
  );
};
